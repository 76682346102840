@font-face {
  font-family: brown;
  font-style: normal;
  font-weight: 400;
  src: url("fonts/brown-bold.ttf") format("truetype");
}

body {
  font-family: brown, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  background-color: #000933;
  color: white;
}

.App {
  display: grid;
}

.title {
  font-size: 6em;
  transform: translateX(-50%);
  color: #fff;
  margin: 5% auto auto;
  line-height: 100px !important;
}
@media only screen and (max-width: 900px) and (min-width: 601px) {
  .title {
    transform: translateX(0);
    line-height: 60px !important;
    font-size: 4em;
    color: #fff;
    margin: 10% auto auto;
  }
}
@media only screen and (max-width: 600px) {
  .title {
    transform: translateX(0);
    line-height: 60px !important;
    font-size: 4em;
    color: #fff;
    margin: 10% auto auto;
  }
}

.caption {
  font-size: 3em;
  line-height: 40px !important;
}
@media only screen and (max-width: 900px) and (min-width: 601px) {
  .caption {
    transform: translateX(5px);
    line-height: 40px !important;
    font-size: 3em;
  }
}
@media only screen and (max-width: 600px) {
  .caption {
    transform: translateX(55px);
    line-height: 40px !important;
    font-size: 3em;
  }
}
