.container {
  width: 100%;
  height: 70%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  opacity: 0.25;
}

.bar-graph {
  height: 78%;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  padding: 0 3%;
  font-size: 0.01%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: justify;
}

.bar-graph .bar {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3%;
  margin: 0 1.13%;
  height: 60px;
  overflow: hidden;
}
.bar-graph .bar:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #52b987;
  left: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -moz-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.bar-graph .bar.dark:after {
  background: #eb4d5c;
  -moz-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.page-loaded .bar-graph .bar:nth-of-type(1):after {
  animation: bar-anim 0.5s ease 0.1s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(2):after {
  animation: bar-anim 0.5s ease 0.2s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(3):after {
  animation: bar-anim 0.5s ease 0.3s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(4):after {
  animation: bar-anim 0.5s ease 0.4s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(5):after {
  animation: bar-anim 0.5s ease 0.5s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(6):after {
  animation: bar-anim 0.5s ease 0.6s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(7):after {
  animation: bar-anim 0.5s ease 0.7s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(8):after {
  animation: bar-anim 0.5s ease 0.8s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(9):after {
  animation: bar-anim 0.5s ease 0.9s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(10):after {
  animation: bar-anim 0.5s ease 1s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(11):after {
  animation: bar-anim 0.5s ease 1.1s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(12):after {
  animation: bar-anim 0.5s ease 1.2s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(13):after {
  animation: bar-anim 0.5s ease 1.3s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(14):after {
  animation: bar-anim 0.5s ease 1.4s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(15):after {
  animation: bar-anim 0.5s ease 1.5s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(16):after {
  animation: bar-anim 0.5s ease 1.6s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(17):after {
  animation: bar-anim 0.5s ease 1.7s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(18):after {
  animation: bar-anim 0.5s ease 1.8s;
  animation-fill-mode: forwards;
}

.page-loaded .bar-graph .bar:nth-of-type(19):after {
  animation: bar-anim 0.5s ease 1.9s;
  animation-fill-mode: forwards;
}

@keyframes bar-anim {
  to {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }
}
